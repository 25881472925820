var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return undefined;
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-prompt"
        }, [_c('div', {
          staticClass: "cyui-prompt-header"
        }, [_c('div', {
          staticClass: "cyui-prompt-pic"
        }, [_c('img', {
          directives: [{
            name: "lazy",
            rawName: "v-lazy",
            value: _vm.promptError,
            expression: "promptError"
          }],
          attrs: {
            "alt": ""
          }
        })])]), _c('div', {
          staticClass: "cyui-prompt-body"
        }, [_c('div', {
          staticClass: "cyui-prompt-message"
        }, [_c('p', {
          staticClass: "cyui-prompt-message-title"
        }, [_vm._v("请求出错可能原因如下：")]), _c('p', [_vm._v(" 系统内部出错；"), _c('br'), _vm._v(" 技术人员在更新系统过程中；"), _c('br'), _vm._v(" 链接服务器超时；"), _c('br'), _vm._v(" ······ ")]), _c('p', {
          staticClass: "cyui-prompt-message-contact"
        }, [_vm._v(" 抱歉给您带来不便，请先检查您手机网络。 ")])]), _c('div', {
          staticClass: "cyui-prompt-button cyui-flexbox"
        }, [_c('div', {
          staticClass: "cyui-flexbox-item"
        }, [_c('div', {
          staticClass: "cyui-btn cyui-btn-gray",
          on: {
            "click": _vm.goHome
          }
        }, [_vm._v("返回首页")])]), _c('div', {
          staticClass: "cyui-flexbox-item"
        }, [_c('div', {
          staticClass: "cyui-btn cyui-btn-ghost",
          on: {
            "click": _vm.goBack
          }
        }, [_vm._v("稍后重试")])])])])])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };